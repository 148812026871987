import { makeNamespace } from "../utils";

const { reducerKey, makeActionTypes } = makeNamespace("upload");

export { reducerKey as key, makeActionTypes };

export const CAPSULE = "CAPSULE";

export const POST = "POST";

export const ROOT = "ROOT";
