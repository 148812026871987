export const makeNamespace = (name) => ({
  reducerKey: name,
  makeActionTypes: (rawActionTypes) =>
    rawActionTypes.reduce(
      (acc, actionType) => ({
        ...acc,
        [actionType]: `${name}/${actionType}`,
      }),
      {}
    ),
});
