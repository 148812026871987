import { actionTypes } from "./actions";

const initialState = {
  isUploading: false,
  error: null,
  parentCapsuleId: null,
  uploadedIds: {
    stories: [],
    capsules: [],
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.UPLOAD_CAPSULE_DATA:
      return {
        ...state,
        isUploading: true,
        parentCapsuleId: payload.parentCapsuleId,
        uploadedIds: {
          stories: [],
          capsules: [],
        },
      };
    case actionTypes.UPLOAD_MORE_CAPSULE_DATA:
      return {
        ...state,
        isUploading: true,
      };
    case actionTypes.UPLOAD_CAPSULE_DATA_SUCCESS:
      return { ...state, isUploading: false };
    case actionTypes.UPLOAD_CAPSULE_DATA_FAILURE:
      return { ...state, isUploading: false, error: payload.error };
    case actionTypes.UPLOAD_CAPSULE_SUCCESS:
      return {
        ...state,
        uploadedIds: {
          ...state.uploadedIds,
          capsules: [...state.uploadedIds.capsules, payload.capsuleId],
        },
      };
    case actionTypes.UPLOAD_STORY_SUCCESS:
      return {
        ...state,
        uploadedIds: {
          ...state.uploadedIds,
          stories: [...state.uploadedIds.stories, payload.storyId],
        },
      };
    default:
      return state;
  }
}
