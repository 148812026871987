import { makeActionTypes } from "./constants";

export const actionTypes = makeActionTypes([
  "UPLOAD_CAPSULE_DATA",
  "UPLOAD_MORE_CAPSULE_DATA",
  "UPLOAD_CAPSULE_DATA_SUCCESS",
  "UPLOAD_CAPSULE_DATA_FAILURE",
  "UPLOAD_CAPSULE_SUCCESS",
  "UPLOAD_STORY_SUCCESS",
]);

export const handleUpload = (treeNodes, parentCapsuleId) => ({
  type: actionTypes.UPLOAD_CAPSULE_DATA,
  payload: {
    treeNodes,
    parentCapsuleId,
  },
});

export const handleMoreUpload = (treeNodes) => ({
  type: actionTypes.UPLOAD_MORE_CAPSULE_DATA,
  payload: {
    treeNodes,
  },
});

export const handleUploadSuccess = () => ({
  type: actionTypes.UPLOAD_CAPSULE_DATA_SUCCESS,
});

export const handleUploadFailure = (error) => ({
  type: actionTypes.UPLOAD_CAPSULE_DATA_FAILURE,
  payload: {
    error,
  },
});

export const uploadCapsuleSuccess = (capsuleId) => ({
  type: actionTypes.UPLOAD_CAPSULE_SUCCESS,
  payload: {
    capsuleId,
  },
});

export const uploadStorySuccess = (storyId) => ({
  type: actionTypes.UPLOAD_STORY_SUCCESS,
  payload: {
    storyId,
  },
});
