import { takeLatest, call, put, fork, select } from "redux-saga/effects";

import {
  actionTypes,
  handleUploadFailure,
  handleUploadSuccess,
  uploadCapsuleSuccess,
  uploadStorySuccess,
} from "./actions";
import { parentCapsuleIdSelector } from "./selectors";
import { initializeTreasuredClient } from "~/contexts/treasured-client";

const editorTemplate = (title, mediaId) => {
  return {
    type: "doc",
    content: [
      {
        type: "title",
        content: [{ type: "text", text: title || "Untitled" }],
      },
      {
        type: "media",
        attrs: {
          type: "image",
          id: mediaId,
          meta: {},
        },
      },
    ],
  };
};

function* createTreeNode({ node, parentCapsuleId }) {
  const client = initializeTreasuredClient();
  if (node.children.length === 0) {
    const postToCreate = {
      title: node.name,
    };
    const createdPost = yield call(
      client.posts.createPost,
      parentCapsuleId,
      postToCreate
    );
    const media = yield call(
      client.media.createMedia,
      node.file,
      createdPost.id,
      "story"
    );
    yield call(client.media.upload, {
      id: media.id,
      url: media.url,
      file: node.file,
    });
    const updates = {
      mediaId: media.id,
      editorContent: editorTemplate(node.name, media.id),
    };
    yield call(client.posts.updatePost, createdPost.id, updates);
    yield put(uploadStorySuccess(createdPost.id));
  } else {
    const capsuleToCreate = {
      title: node.name,
    };
    // TODO: Update this with Exhibit ID or get rid of this altogether
    const newCapsule = yield call(
      client.capsules.createCapsule,
      parentCapsuleId,
      capsuleToCreate
    );
    yield call(createTreeNodes, {
      nodes: node.children,
      parentCapsuleId: newCapsule.id,
    });
    yield put(uploadCapsuleSuccess(newCapsule.id));
  }
}

function* createTreeNodes({ nodes, parentCapsuleId }) {
  for (const node of nodes) {
    yield fork(createTreeNode, { node, parentCapsuleId });
  }
}

function* uploadCapsuleSaga({ payload: { treeNodes } }) {
  const parentCapsuleId = yield select(parentCapsuleIdSelector);
  try {
    yield call(createTreeNodes, { nodes: treeNodes, parentCapsuleId });
    yield put(handleUploadSuccess());
  } catch (err) {
    yield put(handleUploadFailure(err));
  }
}

export function* uploadSagaWatcher() {
  yield takeLatest(
    [actionTypes.UPLOAD_CAPSULE_DATA, actionTypes.UPLOAD_MORE_CAPSULE_DATA],
    uploadCapsuleSaga
  );
}
