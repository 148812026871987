import { createSelector } from "reselect";
import { key } from "./constants";

const uploadSelector = (state) => state[key];

export const isUploadingSelector = createSelector(
  uploadSelector,
  (upload) => upload.isUploading
);

export const parentCapsuleIdSelector = createSelector(
  uploadSelector,
  (upload) => upload.parentCapsuleId
);

export const uploadedStoryIdsSelector = createSelector(
  uploadSelector,
  (upload) => upload.uploadedIds.stories
);

export const uploadedCapsuleIdsSelector = createSelector(
  uploadSelector,
  (upload) => upload.uploadedIds.capsules
);
